<template>
  <div class="dg-ppm__table" data-testid="ppm-contract-list">
    <div class="dg-ppm__table__details">
      <h3 class="dg-ppm__table__title">{{ $t("ppm.list_section.title") }}</h3>
      <div class="dg-ppm__table__search-filter">
        <dg-input :attrs="searchAttrs" :leftIcon="searchIcon" inputType="simple" @keyup.enter="fetchDPAList" />
      </div>
    </div>
    <div :class="['dg-ppm__table__container', { 'dg-ppm__table__container--fetching': fetchingDPAList }]">
      <div class="dg-ppm__table__spinner" v-if="fetchingDPAList">
        <dg-spinner variant="dark" />
      </div>
      <dg-table
        v-if="Boolean(dpaList.length)"
        :rows="dpaList"
        :columns="columnData"
        :pagination="pagination"
        @pageClick="onPageChange"
        @onPageSizeChange="onPageSizeChange"
        @onSort="onSortChange"
      />
      <h3 class="dg-ppm__table__no-data" v-else-if="!fetchingDPAList">
        {{ $t("ppm.list_section.no_data_found") }}
      </h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailsLink from "./DetailsLink.vue";

export default {
  data() {
    return {
      currPage: 1,
      sortCol: "",
      sortOrder: "",
      pageSize: 10,
      searchAttrs: {
        name: "searchContract",
        placeholder: this.$t("ppm.list_section.search_label"),
        value: "",
      },
    };
  },
  computed: {
    searchIcon() {
      return require("../../images/search.svg");
    },
    dpaList() {
      if (this.dpaListData && "list" in this.dpaListData) {
        return this.dpaListData.list.map(dpaContract => {
          const { id, created_at, partner_name, contract_type, workflow_status, participant_role, status } =
            dpaContract;
          return {
            id,
            partner_name,
            contract_type: this.$t(`ppm.list_section.data_labels.type.${contract_type}`),
            workflow_status: this.$t(`ppm.list_section.data_labels.workflow_status.${workflow_status}`),
            participant_role: this.$t(`ppm.list_section.data_labels.role.${participant_role}`),
            status: this.$t(`ppm.list_section.data_labels.status.${status}`),
            created_at: this.formatDate(new Date(created_at), "dd.MM.yyyy"),
          };
        });
      }
      return [];
    },
    columnData() {
      const listOfColumns = [
        { title: this.$t("ppm.list_section.column_labels.vendor"), width: "24", field: "partner_name", sortable: true },
        { title: this.$t("ppm.list_section.column_labels.type"), field: "contract_type", sortable: true },
        {
          title: this.$t("ppm.list_section.column_labels.contract"),
          width: "18",
          field: "workflow_status",
          sortable: true,
        },
        { title: this.$t("ppm.list_section.column_labels.role"), field: "participant_role" },
        { title: this.$t("ppm.list_section.column_labels.status"), width: "18", field: "status" },
        { title: this.$t("ppm.list_section.column_labels.date"), width: "18", field: "created_at", sortable: true },
      ];
      if (this.$can("view", "portal_for_dpas_and_jcas")) {
        listOfColumns.push({ title: "", field: "", width: "8", render: DetailsLink });
      }
      return listOfColumns;
    },
    pagination() {
      let paginationObj = {
        pageSize: 10,
        totalPages: 1,
        defaultPage: 1,
        pageSizeOptions: [10, 20, 30],
      };
      const { pagination } = this.dpaListData;
      if (Object.keys(pagination).length) {
        const { pagination } = this.dpaListData;
        paginationObj["defaultPage"] = pagination.current_page;
        paginationObj["totalPages"] = pagination.total_pages;
        paginationObj["totalCount"] = pagination.total_count;
      }
      return paginationObj;
    },
    ...mapGetters(["companyData", "dpaListData", "fetchingDPAList"]),
  },
  mounted() {
    if (this.companyData && this.companyData.token) {
      this.fetchDPAList();
    }
  },
  methods: {
    fetchDPAList() {
      const metaKeys = {
        page: this.currPage,
        per: this.pageSize,
        search: this.searchAttrs.value,
        only_parents: true,
      };
      if (this.sortOrder) {
        metaKeys["sorted[column]"] = this.sortCol;
        metaKeys["sorted[direction]"] = this.sortOrder;
      }
      this.$store.dispatch("fetchDPAListData", { cid: this.$route.params.id, metaKeys });
    },
    onPageChange(pageNum) {
      this.currPage = pageNum;
      this.fetchDPAList();
    },
    onPageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchDPAList();
    },
    onSortChange(sortData) {
      const { column, sortOrder } = sortData;
      this.sortCol = column;
      this.sortOrder = sortOrder;
      this.fetchDPAList();
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm__table {
  background-color: white;
  margin-top: 60px;
  padding: 32px 24px 18px 24px;

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  &__container {
    position: relative;
    margin-top: 24px;

    &--fetching {
      margin-bottom: 72px;
    }
  }

  &__no-data {
    text-align: center;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.6;

    .dg-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
</style>
